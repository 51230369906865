import osnFetch from "./osnFetch";
import { EndPoints } from "@/constants/consts";
import { HelpRequest  } from "../models/HelpRequest";
import { DBStores } from "../stores/DBStores";
import { dbStoreFactory } from "../stores/DBStoreFactory";
import { helpRequestHub } from "../globals/helpRequestHub";

class HelpRequestService {

    _fetchingData = false;

    async connect() {
        console.log("🚀 ~ HelpRequestService ~ connect:")
        helpRequestHub.helpRequestUpdated((response) => this._helpRequestUpdated(response));
        helpRequestHub.start();

        return this.fetchActiveRequest()
        .then(response => {
            if(!response)
                return this.fetchAllRequestsByResponder();
        })
        .catch(err => {
            console.log(err);
        });
    }

    _userHelpRequest = null;

    hasActiveRequest() {
        if(this._userHelpRequest)
            return true;

        return false;
    }

    async getHelpRequestById(id) {
        const store = dbStoreFactory.getStore(DBStores.HelpRequest);
        return store.getData(id);
    }

    async getHelpRequestsByStatus(status /*HelpStatusType*/) {
        const store = dbStoreFactory.getStore(DBStores.HelpRequest);
        return store.getData({helpStatus: status})
    }

    async getRespondedRequests() {
        const store = dbStoreFactory.getStore(DBStores.HelpRequest);

        return store.getData().then( data => {
            return data.filter( (x) => x.responders.findIndex( r => r.userId == 12) > -1 );
        }) ;
    }

    getActiveRequest() {
        return this._userHelpRequest;
    }

    async fetchActiveRequest() {
        this._fetchingData = true;
        return osnFetch(EndPoints.HelpRequest, "GET", null)
        .then(response => { 
            if(response.ok && response.status == "200"){
                return response.json()
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText);
            }                

            return false;
        })
        .then(response => 
        {
            this._fetchingData = false;
            if(response)
            {
                this._userHelpRequest = response;
                return true;
            }

            this._userHelpRequest = null;
            return false;
        });
    }

    async fetchAllRequestsByResponder() {
        this._fetchingData = true;
        return osnFetch(EndPoints.HelpRequest+'requestsByResponder', 'GET')
            .then(response => { 
                if(response.ok){
                    return response.json()
                }
                else{
                    console.log("Server returned " + response.status + " : " + response.statusText)
                }                
            })
            .then(response => {
                if(!response)
                    return;

                const store = dbStoreFactory.getStore(DBStores.HelpRequest);

                let promises = [];
                if(response.openRequests.length > 0)
                    response.openRequests.forEach(element => {
                        let hr = HelpRequest.FromJson(element);
                        let p = store.insertOrReplaceHelpRequest(hr); 
                        promises.push(p);
                    });

                if(response.inProgressRequests.length > 0)
                    response.inProgressRequests.forEach(element => {
                        let hr = HelpRequest.FromJson(element);
                        let p = store.insertOrReplaceHelpRequest(hr); 
                        promises.push(p);
                    });

                if(response.respondedRequests.length > 0)
                    response.respondedRequests.forEach(element => {
                        let hr = HelpRequest.FromJson(element);
                        let p = store.insertOrReplaceHelpRequest(hr); 
                        promises.push(p);
                    });

                return Promise.allSettled(promises);
            })
            .catch(err => {
                console.log(err);
            })
            .finally( () => {
                this._fetchingData = false;
            });
    }

    async getAllRequestsByResponder()
    {
        while(this._fetchingData)
            await this.timer(1000);

        return dbStoreFactory.getStore(DBStores.HelpRequest).getData();
    }

    async updateCanHelpResponse(helpRequestId, canHelp) {
        return osnFetch(EndPoints.HelpRequest+'canHelpResponse/'+helpRequestId+'/'+canHelp, "PATCH")
        .then(response => { 
            if(response.ok){
                return response.json()
                //return this.fetchAllRequestsByResponder().then( () => { return response; });
            }
            else{
                console.log("Server returned " + response.status + " : " + response.statusText)
                return false;
            }                
        })
        .then(response => {
            if(!response)
                return false;

            console.log("🚀 ~ HelpRequestService ~ updateCanHelpResponse ~ response:", response);

            //return this.fetchAllRequestsByResponder().then( () => { return response; });
            const store = dbStoreFactory.getStore(DBStores.HelpRequest);
            return store.insertOrReplaceHelpRequest(response).then( () => { return true }); 
        })
        .catch(err => {
            console.log(err);
        });
    }

    _helpRequestUpdated(response) {
        dbStoreFactory
            .getStore(DBStores.HelpRequest)
            .update(response.id, {helpStatus:response.helpStatus})
            .then( () => {
                this.onHelpRequestUpdated(response.id, response.helpStatus);
            });
    }

    //being subscribed to
    onHelpRequestUpdated = () => {}; 

    // Returns a Promise that resolves after "ms" Milliseconds
    timer = ms => new Promise(res => setTimeout(res, ms))
}

export const helpRequestService = new HelpRequestService();