import { GeoLocation } from "./Location"
import { HelpRequestedByType } from "../constants/enums"

export class HelpRequest {
    id=''
    messageText= ''
    geoLocation = {
        latitude : 0.0,
        longitude : 0.0
    }
    helpRequestedBy = HelpRequestedByType.Web
    responders = []

    static FromJson(json)
    {
        let temp = Object.create(new HelpRequest())

        Object.keys(json).forEach(key => {
            //going through json object keys, so that temp default values are not overwritten with undefined
            // if they don't exist in json.keys
            temp[key] = json[key];
        })
        return temp;
    }
}

export class HelpRequestRequest {
    //Request DTO
    messageText= ''
    geoLocation = new GeoLocation(0.0, 0.0)
}