export default {
    HelpRequestedBy : ['Web','InReach','Spot']
}

export let TripStatuses = ["Draft", "Ready", "Active", "Finished"]; //This dose not match case insensitive!! use: arr.find(key => key.toUpperCase() === value.toUpperCase())

//TODO: MOVE TO THIS FORMAT instead of export default in this file
export let TripStatusType = Object.freeze({ //TODO: implement
    Draft: 0,
    Ready: 1,
    Active: 2,
    Finished: 3
});

export let HelpRequestedByType = Object.freeze({
    Web: 0,
    InReach: 1,
    Spot: 2,
    Zoleo: 3,

    IsSatComm: function (helpRequestedByType) {
        return helpRequestedByType == this.InReach || helpRequestedByType == this.Zoleo || helpRequestedByType == this.Spot;
    }
});

export let HelpStatusType = Object.freeze({
    Open: 0,
    InProgress: 1,
    Closed: 2,
    Expired: 3
});

export let JoinedFromType = Object.freeze({
    WebApp: 0,
    Email : 1,
    SMS : 2,
    InReach : 3,
    Zoleo : 4,
    Spot : 5,

    IsSatComm: function (joinedFromType) {
        return joinedFromType == this.InReach || joinedFromType == this.Zoleo || joinedFromType == this.Spot;
    }
});

export let ConversationStatus = Object.freeze({
    Active: 0,
    Archived: 1,
    Deleted: 2
});

export let ConversationType = Object.freeze({
    General: 0,
    HelpRequest: 1
});

export const CanHelpState = Object.freeze({ Unknown: 1, True: 2, False: 3 });