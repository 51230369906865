import {BaseStore} from "@/stores/BaseStore";
import {ChatRoom} from "@/models/ChatRoom";
import { DBStores } from "./DBStores";

export class ChatRoomStore extends BaseStore{

    constructor() {
        super(DBStores.ChatRoom);
        super.mapToClass(ChatRoom);
    }

    insertOrReplaceChatRoom(chatRoom)  {
        return super.insertOrReplace(chatRoom);
    }

    getData(id) {
        return super.getData(id);
    }

    getAllData() {
        return super.getAllData();
    }

}