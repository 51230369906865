import { EndPoints } from "../constants/consts";
import { BaseHub } from "./baseHub";

class HelpRequestHub extends BaseHub {

    constructor() {
       super("HelpRequestHub", EndPoints.Hubs+"helpRequest" );
    }

    helpRequestUpdated(callBack) {
        this.connection.on("HelpRequestUpdated", callBack);
    }
}

export const helpRequestHub = new HelpRequestHub();