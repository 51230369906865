import { ChatRoomMessagesStore } from "./ChatRoomMessagesStore";
import { ChatRoomStore } from "./ChatRoomStore";
import { HelpRequestStore } from "./HelpRequestStore";
import { DBStores } from "./DBStores";

class DBStoreFactory {
    #stores = new Map();

    constructor() {
        this.refreshStores();
    }

    refreshStores() {
        this.#stores.set(DBStores.ChatRoom, new ChatRoomStore());
        this.#stores.set(DBStores.ChatRoomMessages, new ChatRoomMessagesStore());
        this.#stores.set(DBStores.HelpRequest, new HelpRequestStore());
    }

    getStore(storeName) {
        return this.#stores.get(storeName);
    }
}

export const dbStoreFactory = new DBStoreFactory();