export class Toast {
    message= ''
    type= ''
    duration= 5000 // milliseconds

    constructor(msg = '', toastType = '') {
        this.message = msg;
        this.type = toastType;
    }
}

export const ToastType = {
    ERROR: 'error',
    ALERT: 'alert',
    INFO: 'info',
    SUCCESS: 'success'
}