import { LocalStorageKeys } from '../constants/consts.js';
import { HubConnectionState } from "@microsoft/signalr";
import logger from '../utilities/logger.js';
const signalr = require('@microsoft/signalr');

export class BaseHub {
    #connection;
    #keepAliveTimer;
    #hubName = '';

    constructor(hubName, hubUrl) {
        if (this.constructor == BaseHub) {
        throw new Error("Abstract classes can't be instantiated.");
        }
        this.#hubName = hubName;

        this.#connection = new signalr.HubConnectionBuilder()
                    .withUrl(hubUrl,  { accessTokenFactory: () => localStorage.getItem(LocalStorageKeys.BearerToken) })
                    .configureLogging(signalr.LogLevel.Debug)
                    .withAutomaticReconnect({
                        nextRetryDelayInMilliseconds: () => {
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        /* if (retryContext.elapsedMilliseconds < 60000) {
                            // If we've been reconnecting for less than 60 seconds so far,
                            // wait between 0 and 10 seconds before the next reconnect attempt.
                            return Math.random() * 10000;
                        } else {
                            // If we've been reconnecting for more than 60 seconds so far, stop reconnecting.
                            return null;
                        } */
                    }})
                    .build();

        this.#connection.onreconnecting(this.onreconnecting.bind(this));
        this.#connection.onreconnected(this.onreconnected.bind(this));
        this.#connection.onclose(this.onclose.bind(this));
    }

    get state() {
        return this.#connection.state;
    }

    get connection() {
        return this.#connection;
    }

    onConnectionStateChanged = null;

    onreconnecting() {
        logger.logDev("Hub reconnecting to: "+ this.#hubName + "");
        if(this.onConnectionStateChanged)
            this.onConnectionStateChanged(this.state);
    }
    onreconnected(connectionId) {
        logger.logDev("Hub reconnected to "+this.#hubName+" with connection ID: " + connectionId);
        if(this.onConnectionStateChanged)
            this.onConnectionStateChanged(this.state);
    }
    onclose(error) {
        if(error)
            console.error("Hub connection to "+this.#hubName+" closed with error: " + error);
        else
            console.log("Hub connection to "+this.#hubName+" closed.");
    }

    start() {
        if(this.state === HubConnectionState.Connected)
            return;

        console.log("🚀 ~ Hub: "+this.#hubName+" ~ start")
        return this.#connection.start( () => {
            // Set up a keep-alive timer
            const keepAliveInterval = 30000; // 30 seconds (adjust as needed)
            this.#keepAliveTimer = setInterval(function () {
                this.#connection.invoke("KeepAlive"); // You need to define a "KeepAlive" method on the server
            }, keepAliveInterval);
        });
    }

    stop() {
        console.log("🚀 ~ Hub: "+this.#hubName+" ~ stop")
        this.#connection.stop(function () {
            clearInterval(this.#keepAliveTimer);
        });
    }
}