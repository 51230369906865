import {EndPoints} from '../constants/consts.js';
import { BaseHub } from './baseHub.js';

class ChatHub extends BaseHub {

    constructor() {
       super("ChatHub", EndPoints.Hubs+"conversation" );
    }

    async sendMessage(chatRoomId, message) {
        let val = await this.connection.invoke("sendMessage", chatRoomId, message)
        return val;
    }

    receiveMessage(callBack) {
        this.connection.on("ReceiveMessage", callBack);
    }

    receiveOwnMessage(callBack) {
        this.connection.on("ReceiveOwnMessage", callBack);
    }

    joinChatRoom(callBack) {
        this.connection.on("JoinChatRoom", callBack);
    }

    conversationUpdated(callBack) {
        this.connection.on("ConversationUpdated", callBack);
    }
}

export const chatHub = new ChatHub();