import { EndPoints } from '@/constants/consts';
import osnFetch from './osnFetch.js';
import { chatHub } from '../globals/chatHub.js';
import { dbStoreFactory } from '../stores/DBStoreFactory.js';
import { DBStores } from '../stores/DBStores.js';
import { ConversationStatus } from '../constants/enums.js';

class ChatRoomsService {
    _isLoading = false;
    _unreadMessages = new Map();

    connect() {
        chatHub.receiveMessage((response) => this.receiveMessage(response));
        chatHub.receiveOwnMessage((response) => this.receiveOwnMessage(response));
        chatHub.joinChatRoom((response) => this.joinConversation(response));
        chatHub.conversationUpdated((response) => this.conversationUpdated(response));
        chatHub.start();
    }

    disconnect() {
        chatHub.stop();
    }

    downloadChatRooms() {
        if(this._isLoading)
            return;

        this._isLoading = true;
        let chatRoomCount = 0;
        let chatRoomsLoaded = 0;

        return osnFetch(EndPoints.ChatRooms, "GET")
        .then(response => {
            if (!response.ok || response.status == "204") {
                this._isLoading = false;
                return;
            }
            
            return response.json()
            .then(chatRooms => {
                //TODO: might want to figure out a way to resolve this before moving to downloading messages
                chatRoomCount = chatRooms.length;

                if(chatRoomCount === 0) {
                    this._isLoading = false;
                    return;
                }

                //TODO: since we only call downloadChatRooms() function on Log In, and on Log Out we clean the indexDB, this function can be simplified to call downloadChatRoomMessages() directly??
                chatRooms.forEach(cr => {
                    dbStoreFactory.getStore(DBStores.ChatRoom).update(cr.id, cr)
                        .then((updated) => {
                        //if chatRoom exists, only updated what ever is in the cr object.
                        //leaving lastMessageId & unreadCount intact as they do not come from the server
                        //otherwise insert the new ChatRoom object.
                        //we can update this if we ever store lastMessageId & unreadCount on the server
                        if (updated)
                            return cr.id;

                        return new Promise ( (resolve) => {
                            //dbStoreFactory.getStore(DBStores.ChatRoom).insertOrReplaceChatRoom(ChatRoom.FromJson(cr))
                            dbStoreFactory.getStore(DBStores.ChatRoom).insertOrReplaceChatRoom(cr)
                            .then( () => {
                                resolve(cr.id);
                            });
                        });
                    })
                    .then( id => {
                        return dbStoreFactory.getStore(DBStores.ChatRoom).getData(id);
                    })
                    .then( cr => {
                        if(cr.status === ConversationStatus.Active)
                        {
                            this.downloadChatRoomMessages(cr)
                                .then( () => {
                                    this._isLoading = ++chatRoomsLoaded < chatRoomCount;
                                });

                            return;
                        }

                        this._isLoading = ++chatRoomsLoaded < chatRoomCount;
                    });
                });
            })
            .catch( e => {
                console.log("error:", e);
            });
            //TODO: add Finally block here to set this._isLoading = false. But will have to refactor chatRooms.forEach block to chain promise properly
        });
    }

    downloadChatRoomMessages(chatRoom) {

        return dbStoreFactory.getStore(DBStores.ChatRoomMessages).getData(chatRoom.id)
            .then(result => { 
                let endPoint;
                if(result)
                    endPoint = EndPoints.ChatRooms+chatRoom.id+ '/messages/'+ (result.lastMessageId == -1 ? '' : result.lastMessageId);
                else
                    endPoint = EndPoints.ChatRooms+chatRoom.id+ '/messages/';

                //console.log("🚀 3 ~ ChatRoomsService ~ downloadChatRoomMessages ~ endPoint:", endPoint)

                return osnFetch(endPoint, "GET")
            })
            .then(response => { 
                if(response.ok){
                    return response.json()
                }
                else{
                    return Promise.reject("Server returned " + response.status + " : " + response.statusText)
                }                
            })
            .then(response => {
                //console.log("🚀 4 ~ ChatRoomsService ~ downloadChatRoomMessages ~ response:", response)
                //TODO: this can move to the caller method
                if(response === null || response === undefined)
                    return Promise.reject("No messages found in the store 2");

                if(response.length == 0 || !response.length)
                {
                    return Promise.reject("No messages found in the store 3");
                }

                return dbStoreFactory
                        .getStore(DBStores.ChatRoomMessages)
                        .insertOrAppendChatRoomMessages(chatRoom.id, response);
            })
            .then(() => {
                //console.log("🚀 5 ~ ChatRoomsService ~ downloadChatRoomMessages ~ Getting ChatRoom from Store:")
                return dbStoreFactory.getStore(DBStores.ChatRoomMessages).getData(chatRoom.id);
            })
            .then((response) => {
                //console.log("🚀 6 ~ ChatRoomsService ~ downloadChatRoomMessages ~ Update ChatRoom:", response)

                return dbStoreFactory
                        .getStore(DBStores.ChatRoomMessages)
                        .update(chatRoom.id, {"lastMessageId": response.messages.at(-1).id, "unreadCount":response.length})
                        .then(() => response);
                        //.resolve(response);
            })
            .then((response) => { 
                //console.log("🚀 7 ~ ChatRoomsService ~ downloadChatRoomMessages ~ addToUnreadMessages:", response)
                if(chatRoom.isActive)
                    return this.addToUnreadMessages(chatRoom.id, response.length);

            })
            .catch( e => {
                console.log("🚀 ~ ChatRoomsService ~ downloadChatRoomMessages ~ e:", e)
            });
    }

    getTotalUnreadMessagesCount() {
        return this._unreadMessages.get("total") ?? 0;
    }

    getUnreadMessageCountByType(conversationType) {
        return this._unreadMessages.get("type:"+conversationType) ?? 0;
    }

    getUnreadMessageCountByConversation(conversationId) {
        return this._unreadMessages.get(conversationId) ?? 0;
           //todo: reactivity doesn't work because this is a promise, breaks badges on UI?
/*         dbStoreFactory.getStore(DBStores.ChatRoom).getData(chatRoomId)
        .then(chatRoom => {
            return chatRoom.unreadCount;
        }); */
        //return 0;
    }

    getUnreadMessageCountByHelpRequest(helpRequestId) {
        return this._unreadMessages.get("helpRequest:" + helpRequestId) ?? 0;
    }

    //todo: this should be private
    addToUnreadMessages(conversationId, newMessageCount=1) {
        return dbStoreFactory
            .getStore(DBStores.ChatRoom)
            .update(conversationId, { "unreadCount": newMessageCount })
            .then( () => {

                if(this._unreadMessages.has(conversationId))
                    this._unreadMessages.set(conversationId, this._unreadMessages.get(conversationId) + newMessageCount);
                else
                    this._unreadMessages.set(conversationId, newMessageCount);

                let totalCount = (this._unreadMessages.get("total") ?? 0 ) + newMessageCount;
                this._unreadMessages.set("total", totalCount);

                return dbStoreFactory.getStore(DBStores.ChatRoom).getData(conversationId);
            })
            .then( chatRoom => {
                let unreadCount = (this._unreadMessages.get("type:"+chatRoom.type) ?? 0) + chatRoom.unreadCount;
                this._unreadMessages.set("type:"+chatRoom.type, unreadCount);

                if(chatRoom.helpRequestId)
                {
                    let unreadCount = (this._unreadMessages.get("helpRequest:"+chatRoom.helpRequestId) ?? 0) + chatRoom.unreadCount;
                    this._unreadMessages.set("helpRequest:"+chatRoom.helpRequestId, unreadCount);
                }

                //console.log("🚀 ~ ChatRoomsService ~ addToUnreadMessages ~ _unreadMessages:", this._unreadMessages)
            }) ;
    }

    clearUnreadMessages(chatRoomId) {
        return dbStoreFactory
            .getStore(DBStores.ChatRoom)
            .update(chatRoomId, { "unreadCount": 0 })
            .then(() => {
                return dbStoreFactory.getStore(DBStores.ChatRoom).getData(chatRoomId);
            })
            .then( chatRoom => { 

                let count = this._unreadMessages.get(chatRoomId) ?? 0;
                this._unreadMessages.delete(chatRoomId);

                if(chatRoom.helpRequestId && count > 0)
                {
                    let temp = this._unreadMessages.get("helpRequest:"+chatRoom.helpRequestId) ?? 0;
                    if(temp > 0)
                        this._unreadMessages.set("helpRequest:"+chatRoom.helpRequestId, temp - count);
                    else
                        this._unreadMessages.delete("helpRequest:"+chatRoom.helpRequestId);
                }

                let temp = this._unreadMessages.get("type:"+chatRoom.type) ?? 0;
                if(temp > 0)
                    this._unreadMessages.set("type:"+chatRoom.type, temp - count);
                else
                    this._unreadMessages.delete("type:"+chatRoom.type);

                if(count > 0)
                    this._unreadMessages.set("total", (this._unreadMessages.get("total") ?? 0) - count);
            });
    }
    
    sendMessage(chatMessage /*ChatMessage*/) {
        //causes error in hub.js if chatHub is a class property
        return chatHub.sendMessage(chatMessage.chatRoomId, chatMessage.messageText);
    }

    receiveOwnMessage(response) {
        dbStoreFactory.getStore(DBStores.ChatRoomMessages).insertOrAppendChatRoomMessages(response.chatRoomId, [response]).then(() => {
            this.onMessageReceived(response.chatRoomId);
        });
    }

    receiveMessage(response) {
        dbStoreFactory
            .getStore(DBStores.ChatRoomMessages)
            .insertOrAppendChatRoomMessages(response.chatRoomId, [response])
            .then(() => {
                this.addToUnreadMessages(response.chatRoomId);
                this.onMessageReceived(response.chatRoomId);
        });
    }

    joinConversation(conversation) {
        dbStoreFactory.getStore(DBStores.ChatRoom)
            .insertOrReplaceChatRoom(conversation)
            .then( () => {
                if(conversation.status === ConversationStatus.Active)
                    this.downloadChatRoomMessages(conversation);

                this.onConversationUpdated(conversation.id);
            });
    }

    conversationUpdated(conversation) { 
        console.log("🚀 ~ ChatRoomsService ~ conversationUpdated ~ conversation:", conversation);

    }

    async getConversation(conversationId) {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoom).getData(conversationId);
    }

    async getChatRooms()
    {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoom).getData();
    }

    async getHelpRequestConversation(helpRequestId) {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoom).getData({helpRequestId: helpRequestId});
    }

    async getChatRoomMessages(chatRoomId)
    {
        while(this._isLoading) {
            await this.timer(1000);
        }

        return dbStoreFactory.getStore(DBStores.ChatRoomMessages).getMessages(chatRoomId);
    }

    //being subscribed to
    onMessageReceived = () => {}; 

    //being subscribed to
    onConversationUpdated = () => {};

    // Returns a Promise that resolves after "ms" Milliseconds
    timer = ms => new Promise(res => setTimeout(res, ms))
}

export const chatRoomsService = new ChatRoomsService();